import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import { pl, lt, ru, enGB, ro, de } from 'date-fns/locale';
import { replace } from 'lodash';
// import { sample } from 'lodash';
import get from 'lodash/get';

import { ArticleI, PostCategories } from '../types/article';
import { CategoryTypes } from '../types/category';
import { langs, Language } from '../types/lang';
import { ImageSize } from '../types/post';

import { showNewsletterByCategory } from './newsletter';

import { matchTrackingDomain } from '@lib/sources';
import { WP_PREMIUM_ID } from 'enums/wordpress';
import { Author } from 'types/author';
export const getArticleUrl = (article: ArticleI) => {
  if (article?.langName === undefined) {
    return `/${get(article, 'slug', get(article, 'postSlug', ''))}`;
  }
  const lang: string = article?.langName.slice(0, 2);

  if (lang === langs.uk || lang === langs.ua) {
    return `${process.env.NEXT_PUBLIC_BASE_URL}/ua/${get(article, 'slug', get(article, 'postSlug', ''))}`;
  }
  return `/${lang || 'pl'}/${get(article, 'slug', get(article, 'postSlug', ''))}`;
};

export const getTagsArticleUrl = (article: ArticleI, lang: string) => {
  if ((article?.langName === langs.uk || article?.langName === langs.ua) && lang !== langs.uk && lang !== langs.ua) {
    return `https://trans.info/${article?.langName}/${get(article, 'slug', get(article, 'postSlug', ''))}`;
  }
  return getArticleUrl(article);
};

export const getAuthorLink = (author: any): string =>
  author?.slug !== '' ? `/user/${author?.slug}` : `/user/${author?.newSlug ?? 'admin'}`;

export const getDateLocale = (lang: string) => {
  const locale = {
    pl: pl,
    de: de,
    en: enGB,
    ro: ro,
    lt: lt,
    ru: ru,
  };
  return locale[lang];
};

export const getCoverPhotoAlt = (article: any, length = 100) => {
  const alt = article?.alt || article?.title;
  return (alt || '').slice(0, length);
};

export const getArticleImgUrl = (article, size: ImageSize = 'medium'): string | null => {
  // panic mode - article?.featuredImage?.sourceUrl - added
  const articleThumb = (article, size) => {
    if (size === 'medium')
      return (
        getCloudflareImageLink(article?.featuredImageMedium) ||
        getCloudflareImageLink(article?.featuredImage?.sourceUrl)
      );
    if (size === 'large')
      return (
        getCloudflareImageLink(article?.featuredImageLarge) || getCloudflareImageLink(article?.featuredImage?.sourceUrl)
      );
    if (size === 'thumb')
      return (
        getCloudflareImageLink(article?.featuredImageThumb) ||
        getCloudflareImageLink(article?.postFeaturedImageThumb) ||
        getCloudflareImageLink(article?.featuredImage?.sourceUrl)
      );
    return getCloudflareImageLink(article?.featuredImage?.sourceUrl);
  };
  if (article?.type === 'videos' || article?.postType === 'videos') {
    const wpVideoThumb = articleThumb(article, size);
    return wpVideoThumb || `https://img.youtube.com/vi/${article?.video}/maxresdefault.jpg`;
  }

  return articleThumb(article, size) || article?.featuredImage || article?.image;
};

export const getDateFromNow = (date: Date, lang = 'pl') =>
  formatDistanceToNow(date, { locale: getDateLocale(lang), addSuffix: true });

export const getLiveStatus = (article: any) => get(article, 'title', '').includes('[LIVE]');

export const getPremiumStatus = (article: any) => {
  return !!article?.categories?.find(
    (category) => category.id === WP_PREMIUM_ID[process.env.NEXT_PUBLIC_ENV || ''][article?.langName],
  );
};

export const getPostMainCategory = (post: ArticleI, isNewsletter = false, lang: Language | undefined = undefined) => {
  const langName = lang || post.langName || 'en';

  const postCategories = get(post, '[0].categories', [] as PostCategories[]).reduce<CategoryTypes[]>(
    (acc, category) => {
      const postCategory = getCategoryData(category.name, true).categoryKey;
      if (!postCategory) return acc;
      if (isNewsletter) {
        return showNewsletterByCategory(langName, postCategory) ? acc.concat([postCategory]) : acc;
      }
      return acc.concat([postCategory]);
    },
    [],
  );

  return postCategories[0] || null;
};

export const getCategoryData = (categoryText: string | null = 'transport', strict = false) => {
  const category = (categoryText || '').toLowerCase();
  const trasnportOptions = ['transport', 'aruszallitas', 'transportas', 'transporte'].map((item) => item.toLowerCase());
  const logisticOptions = [
    'logistyka',
    'logistics',
    'logistik',
    'logisztika',
    'logistika',
    'logistica',
    'logistic',
    'lohistyka',
  ].map((item) => item.toLowerCase());
  const startupsOptions = ['startups', 'startups-de', 'start-ups', 'Startuoliai', 'startapy'].map((item) =>
    item.toLowerCase(),
  );
  const driversOptions = [
    'kierowcy',
    'trucking',
    'truck-drivers',
    'truck drivers',
    'kraftfahrer',
    'truckdrivers',
    'teherszallitas',
    'vairuotojai',
    'camioane',
    'voditeli',
    'camioneros',
    'drivers',
    'vodii',
  ].map((item) => item.toLowerCase());
  const shipmentOptions = ['shipment', 'spedycja', 'speditionen', 'forwarders', 'ekspedytsiya'].map((item) =>
    item.toLowerCase(),
  );
  const analiticsOptions = ['analityka'].map((item) => item.toLowerCase());
  const interviewsOptions = ['interviews', 'interviu'].map((item) => item.toLowerCase());
  const technologyOptions = ['technology', 'tехника', 'technika', 'tekhnika'].map((item) => item.toLowerCase());
  const premiumOptions = ['premium'].map((item) => item.toLowerCase());
  const magazineOptions = ['magazine', 'magazyn'].map((item) => item.toLowerCase());
  const videoOptions = ['video'].map((item) => item.toLowerCase());
  let categoryKey: CategoryTypes | undefined = undefined;

  // TODO update dla WP od 28.05.2021
  const restApiCategories = new Map([
    ['transport', 'transport'],
    ['logistics', 'logistics'],
    ['startups', 'startups'],
    ['trucking', 'trucking'],
    ['shipment', 'forwarders'],
    ['interviews', 'interviews'],
    ['premium', 'premium'],
    ['video', 'videos'],
    ['technology', 'technology'],
    ['magazine', 'magazine'],
    ['analitics', 'analitics'],
  ]);

  const restApiNewsletters = new Map([
    ['driver', 'driver'],
    ['logistician', 'logistician'],
    ['carrier', 'carrier'],
    ['forwarder', 'forwarder'],
    ['shipper', 'shipper'],
  ]);

  const adserver = new Map([
    ['transport', 'Transport'],
    ['logistics', 'Logistyka'],
    ['trucking', 'Kierowca'],
    ['shipment', 'Spedycja'],
  ]);

  const premium = ['premium-transport', 'premium-logistics', 'premium-trucking'];

  if (premium.includes(category)) categoryKey = category as CategoryTypes;
  if (trasnportOptions.includes(category)) categoryKey = 'transport';
  if (logisticOptions.includes(category)) categoryKey = 'logistics';
  if (startupsOptions.includes(category)) categoryKey = 'startups';
  if (driversOptions.includes(category)) categoryKey = 'trucking';
  if (shipmentOptions.includes(category)) categoryKey = 'shipment';
  if (premiumOptions.includes(category)) categoryKey = 'premium';
  if (videoOptions.includes(category)) categoryKey = 'video';
  if (interviewsOptions.includes(category)) categoryKey = 'interviews';
  if (technologyOptions.includes(category)) categoryKey = 'technology';
  if (magazineOptions.includes(category)) categoryKey = 'magazine';
  if (analiticsOptions.includes(category)) categoryKey = 'analitics';

  if (categoryKey === undefined) {
    // logger.error('Category ' + categoryText + ' not found');
    if (strict) return {};
    categoryKey = 'transport';
  }

  return {
    title: `label.${categoryKey}`,
    description: `description.${categoryKey}`,
    categoryKey,
    name: `label.${categoryKey}`,
    restCategoryName: restApiCategories.get(categoryKey),
    newslettersName: restApiNewsletters.get(categoryKey),
    adserver: adserver.get(categoryKey),
  };
};

export const getReadingTime = (content: string, t) => {
  const words = content.split(' ').length;
  const time = Math.ceil(words / 160);
  const rest = time === 1 ? t('posts.minute0') : time <= 4 ? t('posts.minute1') : t('posts.minute2');
  return t('posts.readingTime', { time: time, rest: rest });
};

// get link to the image on cdn-trans.info for URLs returned from Wordpress
export const getImageLink = (sourceUrl: string) => {
  if (process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_ENV === 'production' && sourceUrl) {
    if (sourceUrl.includes('wp-content')) {
      return `https://cdn-trans.info${sourceUrl.split('wp-content')[1]}`;
    } else if (sourceUrl.includes('uploads')) {
      return `https://cdn-trans.info/uploads${sourceUrl.split('uploads')[1]}`;
    }
  }

  if (matchTrackingDomain(sourceUrl)) {
    return `/api/img?url=${encodeURIComponent(sourceUrl)}`;
  }
  return sourceUrl;
};

// get link to the image on cdn-trans.info for URLs returned from the backend API
export const getCloudflareImageLink = (sourceUrl: string) => {
  if (process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_ENV === 'production' && sourceUrl) {
    sourceUrl = replace(
      sourceUrl,
      new RegExp('https://d3w37elt2dcq1p.cloudfront.net/', 'g'),
      'https://cdn-trans.info/',
    );
    sourceUrl = replace(
      sourceUrl,
      new RegExp('https://d2knps3odo7xrl.cloudfront.net/', 'g'),
      'https://cdn-trans.info/',
    );
    return replace(sourceUrl, new RegExp('https://d1dcnte8mfzkpv.cloudfront.net/', 'g'), 'https://cdn-trans.info/');
  }
  if (matchTrackingDomain(sourceUrl)) {
    return `/api/img?url=${encodeURIComponent(sourceUrl)}`;
  }
  return sourceUrl;
};

export const getContactEmail = (lang) => {
  const defaultMail = 'redakcja@trans.info';
  const contactMail = {
    pl: 'redakcja@trans.info',
    de: 'redaktion@trans.info',
    en: 'ggowans@trans.info',
    ro: 'm.stolarczyk@fireup.pro',
    lt: 'redakcija@trans.info',
    ru: 'ru@trans.info',
    ua: 'alysionok@trans.info',
    hu: 'zpolos@trans.info',
  };
  return contactMail[lang] || defaultMail;
};

export const getAuthorPostsCount = (author: Author, lang: Language) => {
  const convertedLang = lang === langs.ua ? langs.uk : lang;
  if (author === undefined || author?.postsCounts === undefined) {
    return 0;
  }
  return author?.postsCounts[convertedLang];
};

export const getFreeMagazineId = (id) => {
  return ['magazin-logistik-im-wandel', 'magazyn-menedzerow-transportu-2-23'].includes(id);
};

interface FullName {
  firstName: string;
  lastName: string;
}

/**
 * Split name to first and last name
 * @param {string} name - full name
 * @returns {FullName} - name split to {firstName: string, lastName: string}
 */
export const getFirstAndLastNameFromFullName = (name: string): FullName => {
  const [firstName, lastName] = [
    name.split(' ').length > 1 ? name.split(' ').slice(0, -1).join(' ') : name,
    name.split(' ').pop() || '',
  ];

  return {
    firstName,
    lastName,
  };
};

export const getBreadcrumbsCategory = (categories, lang, t) => {
  const choosenCategory = categories?.find(
    (category) =>
      category?.id !== WP_PREMIUM_ID[process.env.NODE_ENV][lang] &&
      category?.name !== 'Bez kategorii' &&
      category?.name !== 'Uncategorized',
  );
  if (!choosenCategory) return { label: '', link: '' };

  return {
    label: t(`menu:${choosenCategory.name}`),
    link: t(`menu:link.${choosenCategory.name}`),
  };
};
